import request from './request'

const MANDATE_STATUS = {
  VALID: 'valid',
  PENDING: 'pending',
  INVALID: 'invalid'
}

const MANDATE_METHOD = {
  DIRECT_DEBIT: 'directdebit',
  CREDIT_CARD: 'creditcard'
}

export default {
  data () {
    return {
      MANDATE_STATUS,
      MANDATE_METHOD
    }
  },
  mixins: [request],
  methods: {
    getUserMandates (userId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/payments/${userId}/mandates?pageSize=50`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    filterValidUserMandates (mandates) {
      return mandates
        .filter(mandate => mandate.status === MANDATE_STATUS.VALID)
    },
    createUserMandate ({ userId, redirectUrl } = { userId: null, redirectUrl: window.location.href }) {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            redirectUrl
          }
        }

        this.request('POST', `/payments/${userId}/mandates`, body, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    createPayment ({ ride, amount, mandate, description, invoiceId, invoiceType } = { ride: null, amount: null, mandate: null, description: null, invoiceId: null, invoiceType: 'normal' }) {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            ride,
            amount,
            mandate,
            description,
            invoiceId,
            invoiceType
          }
        }

        this.request('POST', `/payments`, body, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    deleteMandate (id) {
      return new Promise((resolve, reject) => {
        this.request('DELETE', `/payments/mandates/${id}`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    getPayments (params = {}) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/payments`, params, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    getPaymentMethodsAll () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/payments/methods/all`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    }
  },
  computed: {

  }
}
