<template>
  <div :class="{ disabled: loading }">
    <div
      v-if="payInvoiceEnabled"
      class="item mt-3 pt-2">
      <label
        class="item-label py-2">
        <input
          v-if="selectable"
          id="payOnInvoice"
          :checked="payOnInvoice"
          name="mandate.id"
          type="radio"
          class="ml-2 mr-3"
          @change="onPayInvoiceChange"
        >

        <img
          src="../../assets/images/invoice-icon.svg"
          class="mr-3">

        <span>
          ACHTERAF BETALEN
        </span>
      </label>
    </div>
    <div v-if="filteredMandates.length">
      <div
        v-for="(mandate, key) in filteredMandates"
        :key="key"
        xs12
        class="item mt-3 pt-2"
      >
        <label
          :key="mandate.id"
          :for="key"
          class="item-label py-2">
          <input
            v-if="selectable"
            :id="key"
            :value="mandate.id"
            :checked="parseInt(active) === parseInt(mandate.id)"
            name="mandate.id"
            type="radio"
            class="ml-2 mr-3"
            @change="onMandateChange"
          >
          <img
            v-if="allPaymentMethods[mandate.method]"
            :src="allPaymentMethods[mandate.method].images['1x']"
            class="mr-3">
          <span
            v-else
            class="mr-3">{{ mandate.method }}</span>

          <span v-if="mandate.method === MANDATE_METHOD.CREDIT_CARD">
            <span class="hide-md" >{{ mandate.details.cardLabel }}</span>
            <span class="mr-3"> ****{{ mandate.details.cardNumber }}</span>
            <span class="hide-md">{{ mandate.details.cardHolder }}</span>
          </span>
          <span v-else-if="mandate.method === MANDATE_METHOD.DIRECT_DEBIT">
            {{ mandate.details.consumerAccount }}
            <span class="hide-md">{{ mandate.details.consumerName }}</span>
          </span>
          <v-btn
            v-if="canDelete"
            class="btn-remove"
            icon
            @click="onDeteleMandateClick(mandate.id)"
          >
            <v-icon color="red">
              remove_circle
            </v-icon>
          </v-btn>

        </label>

      </div>
    </div>
    <div class="mt-5"/>
    <div
      v-for="(alternate, index) in PAYMENT_ADDITIONAL_METHODS"
      :key="index"
      class="item mt-3 pt-2">
      <label
        class="item-label py-2">
        <input
          v-if="selectable"
          id="alternate"
          :value="alternate"
          :checked="activeAlternate === alternate"
          name="alternate.id"
          type="radio"
          class="ml-2 mr-3"
          @change="onAlternateChange"
        >

        <b>{{ alternate }}</b>

        <v-text-field
          v-model="alternateDescription[alternate]"
          class="ma-0 ml-3 pa-0"
          hide-details
          @change="() => { onAlternateDescriptionChange(alternate) }"
        />
      </label>
    </div>

    <v-layout
      v-if="canAddNewPaymentMethod && paymentMethodAvailable"
      :disabled="loading"
      wrap
      xs12
      class="item mt-3"
      @click="addMandateClick">
      <v-btn
        icon
      >
        <v-icon>
          add
        </v-icon>
      </v-btn>
      <div class="add-mandate-content-wrapper">
        <div class="custom-label black--text mt-1">
          Betaalmiddel toevoegen
        </div>
        <div
          v-if="requireMandateError"
          class="error-text mt-1">
          Voeg een betaalmiddel toe
        </div>
      </div>

    </v-layout>
  </div>
</template>

<script>
import request from '../../helpers/request'
import payment from '../../helpers/payment'

const PAYMENT_ADDITIONAL_METHODS = ['cash', 'izettle', 'stripe']

export default {
  mixins: [request, payment],
  props: {
    alternatePaymentMethod: {
      type: String,
      default: null
    },
    alternatePaymentDescription: {
      type: String,
      default: ''
    },
    activeMandate: {
      type: Number || null || String,
      default: null
    },
    requireMandateError: {
      type: Boolean,
      default: false
    },
    onSelectMandate: {
      type: Function,
      default: () => {}
    },
    onSelectPayInvoice: {
      type: Function,
      default: () => {}
    },
    payInvoiceEnabled: {
      type: Boolean,
      default: false
    },
    activePayOnInvoce: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    },
    canAddNewPaymentMethod: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    userId: {
      type: Number || null,
      default: null
    }
  },
  data () {
    return {
      PAYMENT_ADDITIONAL_METHODS,
      active: null,
      activeAlternate: null,
      payOnInvoice: false,
      filteredMandates: [],
      allPaymentMethods: {},
      loading: false,
      paymentMethodAvailable: false,
      alternateDescription: {
        [PAYMENT_ADDITIONAL_METHODS[0]]: '',
        [PAYMENT_ADDITIONAL_METHODS[1]]: '',
        [PAYMENT_ADDITIONAL_METHODS[2]]: ''
      }
    }
  },
  watch: {
    payInvoiceEnabled: {
      deep: true,
      handler () {
        if (this.$props.selectable) {
          this.prefillData()
        }
      }
    },
    activeMandate: {
      deep: true,
      handler () {
        if (this.$props.selectable) {
          this.prefillData()
        }
      }
    }
  },
  async beforeMount () {
    try {
      this.loading = true

      const { data } = await this.request('GET', '/me/app-config')
      if (data && data.molliePaymentEnabled) {
        this.paymentMethodAvailable = data.molliePaymentEnabled
      }

      await this.fetchMandates()

      if (this.$props.selectable) {
        this.prefillData()
      }

      let allPaymentMethods = {}

      const allPaymentMethodsData = await this.getPaymentMethodsAll()

      allPaymentMethodsData.forEach(item => {
        allPaymentMethods[item.id] = item
      })

      this.allPaymentMethods = allPaymentMethods
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  },
  methods: {
    async fetchMandates () {
      const mandates = await this.getUserMandates(this.$props.userId || localStorage.getItem('dmSecretaryId') || this.$store.getters.getUser.id)
      const filteredMandates = this.filterValidUserMandates(mandates)
      this.filteredMandates = filteredMandates
    },
    prefillData () {
      if (this.$props.alternatePaymentMethod !== null) {
        this.alternateDescription[this.$props.alternatePaymentMethod] = this.$props.alternatePaymentDescription
        this.onAlternateChange({ target: { value: this.$props.alternatePaymentMethod } })
      }

      if (this.$props.activePayOnInvoce && this.$props.payInvoiceEnabled) {
        this.onPayInvoiceChange()
      } else if (this.$props.activeMandate && this.filteredMandates.length && this.filteredMandates.find(item => parseInt(item.id) === parseInt(this.$props.activeMandate))) {
        this.active = this.$props.activeMandate
      } else if (this.filteredMandates.length === 1 && !this.$props.payInvoiceEnabled) {
        this.onMandateChange({ target: { value: this.filteredMandates[0].id } })
      } else {
        this.onMandateChange({ target: { value: null } })
      }
    },
    async addMandateClick () {
      this.loading = true

      try {
        const createMandateData = await this.createUserMandate({ userId: this.$props.userId || localStorage.getItem('dmSecretaryId') || this.$store.getters.getUser.id, redirectUrl: window.location.href })

        window.location.href = createMandateData.redirectUrl
      } catch (error) {
        this.loading = false
      }
    },
    onMandateChange (event) {
      const value = event.target.value
      this.active = value
      this.payOnInvoice = false
      this.$emit('onSelectMandate', value)

      if (value) {
        this.$emit('onSelectPayInvoice', false)
      }
    },
    onPayInvoiceChange () {
      this.active = null
      this.payOnInvoice = true
      this.$emit('onSelectMandate', null)
      this.$emit('onSelectPayInvoice', true)
      this.activeAlternate = null
      this.$emit('onSelectAlternate', null, this.alternateDescription[this.activeAlternate])
    },
    onAlternateChange (event) {
      const value = event.target.value
      this.activeAlternate = value
      this.payOnInvoice = false
      this.$emit('onSelectAlternate', value, this.alternateDescription[this.activeAlternate])

      if (value) {
        this.$emit('onSelectPayInvoice', false)
      }
    },
    onAlternateDescriptionChange (type) {
      if (PAYMENT_ADDITIONAL_METHODS.includes(this.activeAlternate) && this.activeAlternate === type) {
        this.$emit('onSelectAlternate', this.activeAlternate, this.alternateDescription[this.activeAlternate])
      }
    },
    async onDeteleMandateClick (id) {
      this.loading = true

      try {
        await this.deleteMandate(id)
        const mandates = await this.getUserMandates(this.$store.getters.getUser.id)
        const filteredMandates = this.filterValidUserMandates(mandates)

        this.filteredMandates = filteredMandates

        if (!filteredMandates.length || !filteredMandates.find(item => parseInt(item.id) === parseInt(id))) {
          this.onMandateChange({ target: { value: null } })
        }

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .item {
    margin: 0 !important;
    cursor: pointer;
    border-bottom: 1px solid rgba(23, 24, 47, 0.17);
  }

  .error-text {
    color: #AE1C28;
    font-size: 12px;
  }

  .item-title {
    font-size: 16px;
  }

  .add-mandate-content-wrapper {
    align-self: center;
  }

  .size-16 {
    font-size: 16px;
  }

  .item-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .btn-remove {
    margin-left: auto;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .hide-md {
    @media(max-width: 700px){
      display: none;
    }
  }
</style>
